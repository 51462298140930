<template>
  <v-card class="my-3 px-2">
    <v-card-title>
      <span>Módulos:</span>
    </v-card-title>
    <v-card-text>
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col v-for="(value, key) in modules" :key="key" cols="3">
            <v-checkbox v-model="modules[key]" :label="key" hide-details single-line color="primary" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="py-6 px-4">
      <v-spacer />
      <v-btn width="260" color="primary" :loading="saveLoad" @click="openDialog"><v-icon class="mr-1">sync</v-icon> Atualizar Módulos</v-btn>
    </v-card-actions>

    <confirmation-dialog :dialog.sync="dialog" :text="textDialog" @proceed="updateModules" @cancel="closeDialog" />
  </v-card>
</template>

<script>
import { MUTATION_UPDATE_MODULES } from '@/modules/accounts/graphql'
import { cloneDeep } from 'lodash'
import { modules } from '@/constants/modules'

export default {
  components: {
    ConfirmationDialog: () => import('@/components/base/ConfirmationDialog')
  },
  props: {
    accountId: {
      type: String,
      default: ''
    },
    account: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    saveLoad: false,
    dialog: false,
    textDialog: '',
    modules: cloneDeep(modules)
  }),
  mounted() {
    Object.entries(this.account?.modules || []).forEach(([tag, value]) => (this.modules[tag] = Boolean(value)))
  },
  methods: {
    closeDialog() {
      this.dialog = false
      this.text = ''
    },
    openDialog() {
      this.textDialog = 'Atualizar os módulos sobrescreve a configuração anterior de módulos. Tem certeza que deseja salvar?'
      this.dialog = true
    },
    async updateModules() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_MODULES,
          variables: {
            id: this.accountId,
            modules: this.modules
          }
        })
        this.$emit('refresh')
        this.$snackbar({ message: 'Módulos atualizados com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err?.message)
        this.$snackbar({ message: 'Falha ao atualizar módulos', snackbarColor: '#F44336' })
      }
      this.closeDialog()
    }
  }
}
</script>
